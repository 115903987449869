// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-delivery-delivery-tsx": () => import("./../../../src/pages/delivery/Delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-delivery-tsx" */),
  "component---src-pages-drinks-drinks-tsx": () => import("./../../../src/pages/drinks/Drinks.tsx" /* webpackChunkName: "component---src-pages-drinks-drinks-tsx" */),
  "component---src-pages-home-home-tsx": () => import("./../../../src/pages/home/Home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-jobs-jobs-tsx": () => import("./../../../src/pages/jobs/Jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-jobs-tsx" */),
  "component---src-pages-lunch-lunch-tsx": () => import("./../../../src/pages/lunch/Lunch.tsx" /* webpackChunkName: "component---src-pages-lunch-lunch-tsx" */),
  "component---src-pages-menu-menu-tsx": () => import("./../../../src/pages/menu/Menu.tsx" /* webpackChunkName: "component---src-pages-menu-menu-tsx" */),
  "component---src-pages-restaurants-restaurants-tsx": () => import("./../../../src/pages/restaurants/Restaurants.tsx" /* webpackChunkName: "component---src-pages-restaurants-restaurants-tsx" */)
}

